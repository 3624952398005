import React from 'react'
import { NavLink } from "react-router-dom";
import logo from '../assets/ciap_log.png'

const Navbar = () => {
  return (
    <>
    <header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center ">

      {/* <NavLink to="index.html" className="logo me-auto me-lg-0"> */}
       <NavLink to="/" ><img src={logo} style={{height:"64px",width:"64px"}} alt="" className="img-fluid"/></NavLink>
        {/* </a> */}
      {/* <h1 className="m-2 logo me-auto"><NavLink to="/"><span>CI</span>AP</a></h1> */}

      <nav id="navbar" className="navbar order-last order-lg-0 ">
        <ul>
          <li><NavLink to="/">Home</NavLink></li>
          <li><a href="https://www.srmdei.com">DEI</a ></li>
          <li><a href="https://srmciap.com/#/">SRMCIAP</a></li>
          {/* <li><NavLink to="about" >About Us</NavLink></li> */}
          <li className="dropdown"><NavLink to="/"><span>INTELLECTUAL PROPERTY</span> <i className="bi bi-chevron-down"></i></NavLink>
            <ul>
              <li><NavLink to="/ip/Basics">BASICS</NavLink></li>
              <li><NavLink to="/ip/Types">TYPES</NavLink></li>
              <li><NavLink to="/ip/Registration">REGISTRATION PROCCESS</NavLink></li>
            </ul>
          </li>
          <li className='dropdown'><NavLink to="/"><span>Statistics</span> <i className="bi bi-chevron-down"></i></NavLink>
            <ul>
              <li><NavLink to="ps">PATENT STATISTICS</NavLink></li>
              <li><NavLink to="dwps">DEPARTMENT WISE PATENT STATISTICS</NavLink></li>
              <li><NavLink to="ywps">YEAR WISE PATENT STATISTICS</NavLink></li>
              <li><NavLink to="gpl">GRANTED PATENTS LIST</NavLink></li>
            </ul>
          </li>
          <li className='dropdown'><NavLink to="/"><span>Policies</span> <i className="bi bi-chevron-down"></i></NavLink>
            <ul>
              <li><NavLink to="/policies">Policy</NavLink></li>
              
            </ul>
          </li>
          <li><NavLink to="about">Team</NavLink></li>
          {/* <li><NavLink to="message">MESSAGE</NavLink></li> */}
          <li className="dropdown"><NavLink to="/"><span>EVENTS</span> <i className="bi bi-chevron-down"></i></NavLink>
            <ul>
              <li><NavLink to="events2021">2021</NavLink></li>
              <li><NavLink to="events2022">2022</NavLink></li>
            </ul>
          </li>
          {/* <li><NavLink to="upcomingevents">UPCOMING EVENTS</NavLink></li> */}
          <li><NavLink to="news">NEWSLETTER</NavLink></li>
          <li><NavLink to="contact">Contact</NavLink></li>

        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

      

    </div>
  </header>
    </>
  )
}

export default Navbar