import React from 'react'
import { Image } from '@chakra-ui/react';
import DEI from '../assets/DEI_logo.png';
import CIAP from '../assets/ciap_log.png'

const Footer = () => {
    return (
   <>
            <footer id="footer" >

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3>Company</h3>
                                <p>
                                5th Floor, Basic Engineering Lab,<br/>
                                SRM Institute of Science and Technology<br/>
                                Chennai, Tamil Nadu 603203 <br/><br/>
                                                
                                                    {/* <strong>Email:</strong> info@example.com<br/> */}
                                                    </p>
                                                </div>

                                                <div className="col-lg-2 col-md-6 footer-links">
                                                    <h4>Useful Links</h4>
                                                    <ul>
                                                        <li><i className="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                                                        <li><i className="bx bx-chevron-right"></i> <a href="about">Team</a></li>
                                                        {/* <li><i className="bx bx-chevron-right"></i> <a href="upcomingevents">Upcoming Events</a></li> */}
                                                        <li><i className="bx bx-chevron-right"></i> <a href="news">NewsLetters</a></li>
                                                        <li><i className="bx bx-chevron-right"></i> <a href="contact">Contact</a></li>
                                                    </ul>
                                                </div>

                                                <div className="col-lg-3 col-md-6 footer-links">
                                                    <h4> </h4>

                                                </div>

                                                <div className="col-lg-4 col-md-6 footer-newsletter">
                                                    {/* <h4>Join Our Newsletter</h4>
                                                    <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                                                    <form action="" method="post">
                                                        <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                                    </form> */}
                                                    <div className='flex flex-row gap-6'>
                                                    <a href="https://www.srmdei.com"><Image src={DEI} className=' h-auto max-h-40' alt="DEI" /></a>
                                                    <a href="/"><Image src={CIAP} className=' h-auto max-h-40' alt="CIAP" /></a>
                                                    </div>
                                                </div>

                                            </div>
                                            </div>
                                        </div>

                                        <div className="container d-md-flex py-4">

                                            <div className="me-md-auto text-center text-md-start">
                                                <div className="copyright">
                                                    &copy; Copyright <strong><span>CIAP</span></strong>. All Rights Reserved
                                                </div>
                                                <div className="credits">
                                                    Designed by  <a href="https://www.linkedin.com/in/suvan-gowri-shanker-596943261/">Suvan Gowri Shanker</a>
                                                </div>
                                            </div>
                                            <div className="social-links text-center text-md-right pt-3 pt-md-0">
                                                <a href="https://twitter.com/SRM_DEI" className="twitter"><i className="bx bxl-twitter"></i></a>
                                                <a href="https://www.facebook.com/srmiic/" className="facebook"><i className="bx bxl-facebook"></i></a>
                                                <a href="https://www.instagram.com/srmiiec/" className="instagram"><i className="bx bxl-instagram"></i></a>
                                                {/* <a href="/" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                                                <a href="https://www.linkedin.com/school/srmdei/" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                            </div>
                                        </div>
                                    </footer>
                                </>
                                )
}

                                export default Footer