import React from 'react'
// import banner from '../assets/banner.png'
import {
    List,
    ListItem,
    ListIcon,
    Heading,
    Stack,
    StackDivider,
    Text,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    // OrderedList,
    // UnorderedList,
  } from '@chakra-ui/react'

  import { AiOutlineCopyrightCircle, AiOutlineTrademarkCircle } from 'react-icons/ai'
  import { ImPointRight } from 'react-icons/im';
  import { BiWorld, BiChip } from 'react-icons/bi';
  import { PiPlantDuotone } from 'react-icons/pi';
  

const Types = () => {
  return (
    <>
        <br />
        <section className='w-screen h-auto' >
            <img src="https://danielpatents.com/wp-content/uploads/2018/08/orlando-patent.jpg"  width='100%' alt="CIAP Banner" data-aos="fade-down" />
        </section>
        <main className='flex flex-col justify-evenly items-center w-full screen mb-12' data-aos="fade-up">
        <Stack divider={<StackDivider  borderColor='teal.500'/>} spacing={4} align='stretch'>
        <Heading>Types of Intellectual Properties</Heading>

        <p className=' font-serif mx-2 font-normal '>India recognizes various types of IPRs, such as:</p>
        <Tabs>
        <TabList className=' '>
            <Tab>Patents</Tab>
            <Tab><AiOutlineTrademarkCircle/>Trademarks</Tab>
            <Tab><AiOutlineCopyrightCircle/> Copyrights</Tab>
            <Tab>Designs</Tab>
            <Tab><BiWorld/>Geographical Indications </Tab>
            <Tab><PiPlantDuotone/>Plant Varietes </Tab>
            <Tab><BiChip/>Semi-Conductors </Tab>
        </TabList>
            <TabPanels>
                <TabPanel>
                <section className='accbutton'>
                    <Stack divider={<StackDivider borderColor='teal.500'/>} spacing={4} align='stretch'>
                        <Heading>Brief</Heading>
                        <List className='mx-2'>
                            <ListItem><ListIcon as={ImPointRight} color='teal.500' /> A Patent is the statutory protection for an invention granted for a limited period of time to the patentee by the Government, in exchange of full disclosure of his invention. </ListItem>
                            <ListItem><ListIcon as={ImPointRight} color='teal.500' /> A Patent is a country specific right </ListItem>
                            <ListItem><ListIcon as={ImPointRight} color='teal.500' /> A Patent gives monopolistic rights to its owner to exclude others, from making, using, selling, offering for sale or importing the product or the process for producing the product without his consent </ListItem>
                            <ListItem><ListIcon as={ImPointRight} color='teal.500' /> The Patents Act, 1970 and the Patents Rules 1972 subsequently amended in 1999, 2002, 2005 and 2016 govern the provisions relating to Patents in India </ListItem>
                        </List>
                        <Accordion >
                            <AccordionItem>
                            <AccordionButton className=" bg-teal-400 rounded-md my-1 " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }} >
                                <Box as="span" flex='1' className='accbutton ml-12' textAlign='left'>
                                    What can be patented in India?
                                </Box>
                                <AccordionIcon color='teal.500'/>
                            </AccordionButton>
                            <AccordionPanel >
                            <List spacing={4}>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Any product or process which is new, non- obvious and capable of industrial application can be patented.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />A patent is not granted if the invention is already available in the public domain in any form; published literature anywhere in the world or through common knowledge.</ListItem>                                
                            </List>
                            </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                            <AccordionButton className=" bg-teal-400 rounded-md my-1 " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }} >
                                <Box as="span" flex='1' className='accbutton ml-12' textAlign='left'>
                                    Patent Terms
                                </Box>
                                <AccordionIcon color='teal.500'/>
                            </AccordionButton>
                            <AccordionPanel >
                                <Text>The following persons are entitled to apply for patents</Text>
                            <List spacing={4}>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Any person claiming to be the true and first inventor of the invention, either alone or jointly with any other person</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Any person being the assignee of the person claiming to be the true and first inventor in respect of the right to make such an application, either alone or jointly with any other person</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Legal representative of any deceased person who immediately before his death was entitled to make such an application</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} /><strong>Note : </strong>The term of every patent in India is twenty years from the date of filing the Patent application.</ListItem>
                                
                            </List>
                            </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                            <AccordionButton className=" bg-teal-400 rounded-md my-1 " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }} >
                                <Box as="span" flex='1' className='accbutton ml-12' textAlign='left'>
                                    Benefits of Registering for a Patent
                                </Box>
                                <AccordionIcon color='teal.500'/>
                            </AccordionButton>
                            <AccordionPanel >
                                
                            <List spacing={4}>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Patentee has the exclusive rights to use the Patent.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Patentee or an authorised person can initiate legal action in the case of infringement.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Patentee can commercially exploit the potential of his product/process; no one can copy or use without the permission of the during the period of the patent.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Patented products can be licensed, assigned or sold for commercial consideration</ListItem>
                                
                            </List>
                            </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton'>
                <Stack divider={<StackDivider borderColor='teal.500'/>} spacing={4} align='stretch'>
                    <Heading>Basics</Heading>
                    <Stack spacing={5}>
                    <Text>
                    A trademark is a mark which is unique, distinctive, made up of names, symbols, signs, sounds, images, shape, packaging, colour combination, and capable of distinguishing one product from another.
                    </Text>
                    <Text>Trademark is a mark accorded with protection, intended to serve the purpose of recognizing the source/origin of the goods or services to which the particular mark belongs.</Text>
                        <Text>Functions of a Trademark :</Text>
                        <List spacing={4} className='ml-4'>
                            <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} /> Identification – Identifythe origin of the product/ service.</ListItem>
                            <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} /> Source – Distinguishproduct/ service of the proprietor from those of others.</ListItem>
                            <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} /> Quality – To guarantee its unchanged quality.</ListItem>
                            <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} /> Advertising – To advertise the product/service</ListItem>
                        </List>
                    </Stack>

                    <Text>
                    Trademark is a symbol of origin and source of a mark and bears the ‘stamp’ of quality.<br/><br/>

    The trademark owner would always want to protect his mark from unfair usage and also from fraud and deceit.
                    </Text>
                </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton' >
                    <Stack divider={<StackDivider borderColor='teal.500' spacing={4} align='strech'/>}>
                        <Heading>Basics</Heading>
                        <Stack spacing={5} >
                            <List spacing={4} className='ml-4'>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Copyright is an exclusive legal right granted to the creators of intellectual work.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Copyright protects “original works of authorship” that are fixed in “a tangible form of expression.”</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Copyright owner has rights to reproduce, translate, adapt, perform, distribute and publicly display the work, etc.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Registration is not mandatory since copyright comes into existence as soon as the intellectual work is created but registration of copyright has immense evidentiary value in the Court and hence registration of a copyright is recommended.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />The Copyright Act, 1957 governs the provisions relating to copyrights in India.</ListItem>
                            </List>
                        </Stack>
                    </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton' >
                    <Stack divider={<StackDivider borderColor='teal.500' spacing={4} align='strech'/>}>
                        <Heading>Basics</Heading>
                        <Stack spacing={5} >
                            <List spacing={4} className='ml-4'>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Industrial Designs are the features of shape, configuration, pattern, ornament or composition of lines or colours applied to the product which makes it look different from other articles in the market.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />The purpose of industrial designs is to add on to the aesthetic value of the product to increase the marketability while making our life easier</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />The Designs Act 2000 governs the provisions relating to designs in India and specifically mentions that the design to be registered must be visible on the finished article.</ListItem>
                            </List>                            
                        </Stack>
                    </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton' >
                    <Stack divider={<StackDivider borderColor='teal.500' spacing={4} align='strech'/>}>
                        <Heading>Basics</Heading>
                        <Stack spacing={5} >
                            
                            <Text>Geographical Indication is primarily an agricultural, natural or a manufactured product (handicrafts and industrial goods) originating from a definite geographical territory.</Text>
                            <Text>Typically, such a name conveys an assurance of quality and distinctiveness which is essentially attributable to the fact of its origin in that defined geographical locality.</Text>
                            <Text>People with a malicious intention to sell other variety of teas with the title/label “Darjeeling” can be punished.</Text>
                            <Text>Some examples are Champagne,  Darjeeling Tea, Chanderi Saree, Kanchipuram Silk Saree, Nagpur Orange,  Bikaner Bhujia, etc.</Text>
                       
                        </Stack>
                    </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton' >
                    <Stack divider={<StackDivider borderColor='teal.500' spacing={4} align='strech'/>}>
                    <Heading>Protection of Plant Varieties and Farmers’ Rights Act, 2001</Heading>
                        <Stack spacing={5} >
                            
                            <List spacing={4} className='ml-4'>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />The Protection of Plant Variety and Farmers’ Rights Act was passed by the Indian Parliament in 2001.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Rules were notified by the Department of Agriculture (now known as Department of Agriculture and Farmers Welfare) in 2003.</ListItem>
                                <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />According to Article 27.3 (b) of TRIPs Agreement<br/>“…members shall provide for the protection of plant varieties either by patents or an effective sui generis system or by any combination thereof”</ListItem>
                            </List>
                        </Stack>
                    </Stack>
                </section>
                </TabPanel>
                <TabPanel>
                <section className='accbutton' >
                    <Stack divider={<StackDivider borderColor='teal.500' spacing={4} align='strech'/>}>
                        <Heading>Basics</Heading>
                        <Stack spacing={5} align='center'>
                            <Text><strong>What are Semiconductor Integrated Circuit (SIC)?<br/></strong></Text>
                            <Text>Semiconductor Integrated Circuit means a product having transistors and other circuitry elements, which are inseparably formed on or inside a semiconductor material or an insulating material and designed to perform an electronic circuitry function.</Text>
                            <Text>Sometimes called chips or microchips, these IC chips form an integral part of every electronic device like computers, television, electronic watch, mobile phones etc.</Text>
                        </Stack>
                    </Stack>
                </section>
                </TabPanel>
            </TabPanels>
            </Tabs>
            
            </Stack>

        
        </main>
    </>
  )
}

export default Types