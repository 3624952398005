import { React, useEffect, useState } from 'react'
import banner from '../assets/banner.png'
// import Imgloader from "./Imgloader";

import {
  Image,
  Spinner,
  Stack,
  Heading,
  Box,
  StackDivider,
  Divider,
} from '@chakra-ui/react'


const Registration = (props) => {
  useEffect(() => {
    document.title = `${props.titleChange} - Registration Process`;
    //eslint-disable-next-line
}, [])

const [ data, setData] = useState([]);
const [ isLoading, setisLoading] = useState(true);

useEffect(() => {
  setTimeout(() => {
    import('./reg.json').then((data) => {
        setData(data.default); 
        setisLoading(false); 
    })
    .catch((error) => {
        console.error('Error importing committee.json', error);
        setisLoading(false);
    })
    }, 3000); 
}, [] );
 
  return (
    <>
      <section className='w-screen h-auto mt-2' >
        <img src={banner}  width='100%' alt="CIAP Banner" data-aos="fade-down" />
      </section>
      <main className='flex flex-col justify-evenly items-center w-full '>
        <Heading>Registration Process</Heading>
        <Divider color='teal.500' w='3xl'/>
        {isLoading ? (
          <Spinner color='teal.500' size='xl'/>
        ) : (
          data.map((fields) => (
            <Box boxSize='4xl' data-aos="fade-up"  margin='32'>
            <Stack divider={<StackDivider borderColor='teal.200' />}>
              <Heading>{fields.title}</Heading>
              <Image src={fields.url} alt="Registration Process" />
            </Stack>
            </Box>
          ))
        )}


      </main>

  
    </>
  )
}

export default Registration