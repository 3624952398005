import React, {useState, useEffect} from 'react'

import {
  Text,
  Image,
  Card,
  CardBody,
  CardFooter,
  // CardHeader,
  Divider,
  Box,
  Stack,
  SkeletonText,
  Heading,
  // StackDivider,
  // Button,
} from '@chakra-ui/react'

import { AiOutlineMail, AiOutlineLinkedin } from 'react-icons/ai'

const About = (props) => {
    useEffect(() => {
        document.title = `${props.titleChange} - SIDC`;
        //eslint-disable-next-line
    }, [])

    const [teamData, setteamData] = useState([]);
    const [ teamLoading, setteamLoading] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        import('./team.json').then((data) => {
            setteamData(data.default); 
            setteamLoading(false); 
        })
        .catch((error) => {
            console.error('Error importing committee.json', error);
            setteamLoading(false);
        })
        }, 3000); 
    }, [] );

  return (
   <>
   <section id="breadcrumbs" className="breadcrumbs ">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>About</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li>About</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="team" className="team section-bg">
      <div className="container">

        <div className="section-title text-teal-700" data-aos="fade-up">
          <h2>Our <strong>Team</strong></h2>
          
        </div>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center'>
        {teamLoading ? (
           <Stack className='mx-2 flex flex-row w-screen gap-2'>
              <Box padding='12' boxShadow='lg' bg='white' w='30vw'>                
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
              </Box>
              <Box padding='12' boxShadow='lg' bg='white' w='30vw'>                
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
              </Box>
              <Box padding='6' boxShadow='lg' bg='white' w='30vw'>                
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                <SkeletonText startColor='teal.100' endColor='teal.600' mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
              </Box>
            </Stack>
        ) : (
          teamData.map((person, index) => (
            <Card maxW='sm' key={index}>
            <CardBody>
              <Image
                src={person.image}
                alt='Team'
                borderRadius='lg'
                objectFit='cover'
                boxSize="sm"
              />
              <Stack mt='6' spacing='3'>
                <Heading size='md'>{person.name}</Heading>
                <Text>
                {person.designation}
                </Text>
                
              </Stack>
            </CardBody>
            <Divider />
            <CardFooter className='shadow-md'>
              <div className='flex flex-row'>
                <a href={`mailto:${person.emailid}`} className='w-max text-primaryYellow'>
                    <AiOutlineMail style={{width:'32px'}}/>     
                </a>
                <a href={person.linkedin} className='w-max text-primaryYellow'>
                    <AiOutlineLinkedin style={{width:'32px'}}/>     
                </a>
              </div>
            </CardFooter>
          </Card>
          ))
        )}
        </div>


        {/* <div className="row">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up">
              <div className="member-img">
                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="Team Member "/>
                <div className="social">
                  <a href="/"><i className="bi bi-twitter"></i></a>
                  <a href="/"><i className="bi bi-facebook"></i></a>
                  <a href="/"><i className="bi bi-instagram"></i></a>
                  <a href="/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="100">
              <div className="member-img">
                <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="Team Member "/>
                <div className="social">
                  <a href="/"><i className="bi bi-twitter"></i></a>
                  <a href="/"><i className="bi bi-facebook"></i></a>
                  <a href="/"><i className="bi bi-instagram"></i></a>
                  <a href="/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="200">
              <div className="member-img">
                <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="Team Member "/>
                <div className="social">
                  <a href="/"><i className="bi bi-twitter"></i></a>
                  <a href="/"><i className="bi bi-facebook"></i></a>
                  <a href="/"><i className="bi bi-instagram"></i></a>
                  <a href="/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay="300">
              <div className="member-img">
                <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="Team Member "/>
                <div className="social">
                  <a href="/"><i className="bi bi-twitter"></i></a>
                  <a href="/"><i className="bi bi-facebook"></i></a>
                  <a href="/"><i className="bi bi-instagram"></i></a>
                  <a href="/"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
            </div>
          </div>

        </div> */}

      </div>
    </section>
   </>
  )
}

export default About