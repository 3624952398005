import React from 'react'
import banner from '../assets/banner.png'
import {
    List,
    ListItem,
    ListIcon,
    Heading,
    Stack,
    StackDivider,
    Text,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    // OrderedList,
    // UnorderedList,
  } from '@chakra-ui/react'
  import { BsDot } from 'react-icons/bs'

const Policies = () => {
  return (
    <>
        <section className='w-screen h-auto mt-2' >
            <img src={banner}  width='100%' alt="CIAP Banner" data-aos="fade-down" />
        </section>
        <main className='flex flex-col justify-evenly items-center w-full mb-12' data-aos="fade-up">
            <Stack divider={<StackDivider  borderColor='teal.500'/>} spacing={4} align='stretch' className='w-11/12'>
                <Heading className='mx-6'>Our Policies</Heading>
                <List spacing={4} className='mx-12'>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Formulated after intensive stakeholder consultation with nearly 300 organisations and individuals by an IPR Think Tank, as also 31 departments of the Government of India</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Approved on 12 th May 2016 by the Government of India</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />A vision document that encompasses and brings to a single platform all IPRs, taking into account all inter-linkages within the Indian IP ecosystem, aimed at creating and exploiting synergies between all forms of intellectual property (IP), concerned statutes and agencies</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Sets in place an institutional mechanism for implementation, monitoring and review and aims to incorporate and adapt global best practices to the Indian scenario</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Reiterates India’s commitment to the Doha Development Agenda and the TRIPS agreement</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Recognizes that India has a well-established TRIPS-compliant legislative, administrative and judicial framework to safeguard IPRs</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Balances the interests of rights owners with larger public interest</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />To create awareness about IPRs as a marketable financial asset and economic tool; ensure monetization of India’s ‘frugal engineering’ strength</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Serves as a guide on the IPR regime in India for times to come by ensuring that the IP regime is characterized by single-mindedness of purpose and direction</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />Positions India as an economy conducive to innovation and enables channelizing of the strengths of various stakeholders for the same</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />To place before the world a vibrant and predictable IP regime with a stable, transparent and service-oriented IPR administration to stimulate creativity and innovation</ListItem>
                    <ListItem fontFamily='sans-serif'><ListIcon as={BsDot} />IPR Policy to be reviewed every five years; Committee under Secretary, DIPP for regular appraisal</ListItem>
                </List>
            </Stack>
            
            <Accordion allowToggle>
              <AccordionItem>
              <AccordionButton className=" bg-teal-400 rounded-md my-1 " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
                <Box as="span" flex='1' className='accbutton ml-12' textAlign='center'>
                  National IPR Policy
                </Box>
                <AccordionIcon color='teal.500'/>
              </AccordionButton>
              <AccordionPanel>
              <div className='h-screen'>
              <object
                data={require("../assets/National-IPR-Policy.pdf")}
                type="application/pdf"
                width="100%"
                height="100%"
                aria-label='Action Plan:2020-21 PDF'
              ></object></div>
              </AccordionPanel>
              </AccordionItem>
            </Accordion>

            
        </main>
    </>
  )
}

// Formulated after intensive stakeholder consultation with nearly 300 organisations and individuals by an IPR Think Tank, as also 31 departments of the Government of India
// Approved on 12 th May 2016 by the Government of India
// A vision document that encompasses and brings to a single platform all IPRs, taking into account all inter-linkages within the Indian IP ecosystem, aimed at creating and exploiting synergies between all forms of intellectual property (IP), concerned statutes and agencies
// Sets in place an institutional mechanism for implementation, monitoring and review and aims to incorporate and adapt global best practices to the Indian scenario
// Reiterates India’s commitment to the Doha Development Agenda and the TRIPS agreement
// Recognizes that India has a well-established TRIPS-compliant legislative, administrative and judicial framework to safeguard IPRs
// Balances the interests of rights owners with larger public interest
// To create awareness about IPRs as a marketable financial asset and economic tool; ensure monetization of India’s ‘frugal engineering’ strength
// Serves as a guide on the IPR regime in India for times to come by ensuring that the IP regime is characterized by single-mindedness of purpose and direction
// Positions India as an economy conducive to innovation and enables channelizing of the strengths of various stakeholders for the same
// To place before the world a vibrant and predictable IP regime with a stable, transparent and service-oriented IPR administration to stimulate creativity and innovation
// IPR Policy to be reviewed every five years; Committee under Secretary, DIPP for regular appraisal


export default Policies