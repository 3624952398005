import React from 'react'
import banner from '../assets/banner.png'
import {
    List,
    ListItem,
    ListIcon,
    Stack,
    StackDivider,
    Heading,
    Text,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    // OrderedList,
    // UnorderedList,
  } from '@chakra-ui/react'

 import { AiOutlineArrowRight } from 'react-icons/ai';
 import { ImPointRight } from 'react-icons/im';
 import { BsDot } from 'react-icons/bs'

const Basics = () => {
  return (
    <>
    <br />
   <section className='w-screen h-auto' >
    <img src={banner}  width='100%' alt="CIAP Banner" data-aos="fade-down" />
   </section>
    <main className='w-full flex flex-col justify-evenly items-center my-12' data-aos="fade-up">
        <Stack divider={<StackDivider  borderColor='teal.500'/>}>
            <Heading >What are Intellectual Properties?</Heading>
        <List spacing={5}>
        <ListItem  fontFamily='sans-serif'>
            <ListIcon as={AiOutlineArrowRight} color='green.500' />
            Intellectual Property (IP) means the property, which is created with intellect such as inventions, books, paintings, songs, symbols, names, images, or designs used in business, etc.
        </ListItem>
        <ListItem fontFamily='sans-serif'>
            <ListIcon as={AiOutlineArrowRight} color='green.500' />
            IP is a property which cannot be touched or seen and is product of a person's intelligence, hard work, and skill.
        </ListItem>
        <ListItem fontFamily='sans-serif'>
            <ListIcon as={AiOutlineArrowRight} color='teal.500' />
            IP, like any real property that <p className='ml-12'> {`i) can be bought, sold, licensed, exchanged, given away`}<br/> {`ii) The owner can prevent unauthorized use and can take legal action, in case someone else uses it without permission.`}</p>
        </ListItem>
        <ListItem fontFamily='sans-serif'>
            <ListIcon as={AiOutlineArrowRight} color='teal.500' />
            Legal rights conferred on such property are called “Intellectual Property Rights” (IPRs).
        </ListItem>
        </List>

        <Accordion allowToggle >
            <AccordionItem>
                <h2>
                <AccordionButton className=" bg-teal-400 rounded-md my-1 " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }}>
                    <Box as="span" flex='1' className='accbutton ml-12' textAlign='left'>
                    <h2>Why is Intellectual Property Rights important?</h2>
                    </Box>
                    <AccordionIcon color='teal.500' />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className='accbutton'>
                <List spacing={4}>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Encourages creation of new, path breaking inventions.</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Helps authors, creators, and owners to be incentivized for their work.</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Allows the work created by a person to be distributed and communicated to public only with his permission. Therefore, helps in prevention of loss of income.</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Helps authors, creators, developers and owners to get recognition for their works.</ListItem>
                    
                </List>
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton className=" bg-teal-400 rounded-md  " _hover={{bg:'rgba(13, 148, 136 ,0.4)'}} _expanded={{ bg: '#fff', color: 'black' }} >
                    <Box as="span" flex='1' className='accbutton ml-12' textAlign='left'>
                    How can you help Intellectual property?
                    </Box>
                    <AccordionIcon color='teal.500' />
                </AccordionButton>
                </h2>
                <AccordionPanel pb={4} className='accbutton'>
                <List spacing={4}>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Be creative and innovative.</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Produce original work.</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Buy only real and original products – Stop Piracy and Counterfeiting!</ListItem>
                    <ListItem fontFamily='serif' ><ListIcon as={ImPointRight} />Always respect others rights and give due credits – Never Steal!</ListItem>
                    
                </List>
                </AccordionPanel>
            </AccordionItem>
            </Accordion>

        </Stack>
    </main>
    </>
  )
}

export default Basics